import { faRightToBracket } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import envData from '../../../../config/env.json';
import { isSignedInSelector } from '../../../redux/selectors';
import callGA from '../../../analytics/call-ga';

const { apiLocation, homeLocation } = envData;

const mapStateToProps = createSelector(isSignedInSelector, isSignedIn => ({
  isSignedIn
}));

interface LoginProps {
  block?: boolean;
  children?: React.ReactNode;
  'data-test-label'?: string;
  isSignedIn?: boolean;
}

const LoginToSee = ({
  block,
  children,
  'data-test-label': dataTestLabel,
  isSignedIn
}: LoginProps): JSX.Element => {
  const { t } = useTranslation();
  const href = isSignedIn ? `${homeLocation}/` : `${apiLocation}/signin`;

  return (
    <a
      className='login-button-new'
      data-test-label={dataTestLabel}
      href={href}
      onClick={() => {
        callGA({
          event: 'sign_in'
        });
      }}
    >
      <span className='text-login-details'>
        See More Details
      </span>

      <style>
        {
          `
          .text-login-details {
            font-size:16px;
            color:white;
          }
            @media (max-width: 980px) {
            .text-login-details {
            font-size:12px;
            color:white;
          }
            }
          `
        }
      </style>
    </a>

  );
};

LoginToSee.displayName = 'LoginToSee';

export default connect(mapStateToProps)(LoginToSee);
